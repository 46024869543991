<template>
  <el-select
    v-model="domainModel"
    @change="domainChanged"
    autocomplete="new-password"
    :placeholder="placeholder ? placeholder : $t('COMMON.DOMAIN')"
    :filterable="filterable"
    :multiple="false"
    :disabled="disabled"
    remote
    :remote-method="getDomains"
    :loading="loading"
  >
    <el-option
      v-if="showAll"
      :value="null"
      :label="placeholder ? placeholder : $t('COMMON.ALL_DOMAINS')"
    >
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="domain in domains"
      :key="domain.id"
      :value="domain.id"
      :label="`${domain.name} - ${domain.organization?.name ?? ''}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "domain-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [requestErrorMixin],

  props: {
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder of input",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    domain: {
      type: String,
      default: null,
      description: "domain id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterOrganizationNull: {
      type: Boolean,
      default: null,
      description: "Filter only null organizations",
    },
  },

  data() {
    return {
      domainModel: this.domain,
      domains: {},
    };
  },

  setup() {},

  created() {
    this.getDomains(null, this.domain);
  },

  methods: {
    async getDomains(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { with_ids: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterOrganizationNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: null,
            },
          };
        }

        await this.$store.dispatch("domains/list", params);
        const domainsArr = await this.$store.getters["domains/list"];
        this.domains = {};
        domainsArr.forEach((domain) => {
          this.domains[domain.id] = domain;
        });
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    domainChanged(domain) {
      this.$emit("domainChanged", domain);
    },
  },

  watch: {
    domain(domain) {
      if (domain) {
        this.domainModel = domain;
        if (domain !== this.domainModel) {
          this.getDomains(null, domain);
        }
      } else {
        this.domainModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getDomains();
    },
    filterOrganizationNull(filterOrganization) {
      this.getDomains();
    },
  },
};
</script>
